import axios from "axios";
import { BASEURL } from "services";

// Set base URL for all requests
axios.defaults.baseURL = BASEURL;

/**
 * Create an Axios instance with default configuration
 */
const apiClient = axios.create({
  baseURL: BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Utility function to get tokens from localStorage
 */
const getAuthTokens = () => JSON.parse(localStorage.getItem("auth"));

/**
 * Utility function to save tokens to localStorage
 */

const isAccessTokenExpired = (token) => {
  const expiryTime = JSON.parse(atob(token.split(".")[1])).exp * 1000;
  return Date.now() >= expiryTime;
};

/**
 * Add a request interceptor to attach the token to all requests
 */
apiClient.interceptors.request.use((config) => {
  const authToken = getAuthTokens()?.access;
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

/**
 * Add a response interceptor to handle 401 Unauthorized errors
 */
apiClient.interceptors.request.use(
  async (config) => {
    const authToken = getAuthTokens()?.access;
    if (authToken && isAccessTokenExpired(authToken)) {
      console.log("Access token expired. Refreshing...)");
      const refreshToken = getAuthTokens()?.refresh;
      const user_role = getAuthTokens()?.role;

      if (!refreshToken) {
        console.error("No refresh token found.");
        return Promise.reject("No refresh token found");
      }

      try {
        const { data } = await axios.post(`${BASEURL}api/token/renew/`, {
          refresh: refreshToken,
          role: user_role,
        });
        localStorage.setItem("auth", JSON.stringify(data));
        config.headers["Authorization"] = `Bearer ${data.access}`;

      } catch (refreshError) {
        if (refreshError.response?.status === 401) {
          console.error(
            "Session expired:",
            refreshError.response?.data || refreshError.message
          );
          localStorage.clear();
          alert("Your session has expired. Please log in again.");
          window.location.href = "/login";
        }
        return Promise.reject(refreshError);
      }
    } else if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * GET request with token
 * @param {string} path - API endpoint
 * @returns {Promise} - Axios promise
 */
export const getApi = (path) => apiClient.get(path);

/**
 * GET request without token
 * @param {string} path - API endpoint
 * @returns {Promise} - Axios promise
 */
export const getAPIWithoutToken = (path) => axios.get(path);

/**
 * POST request with data
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @returns {Promise} - Axios promise
 */
export const postApi = (path, data) => apiClient.post(path, data);

/**
 * POST request with file (multipart/form-data)
 * @param {string} path - API endpoint
 * @param {object} data - File data to send
 * @returns {Promise} - Axios promise
 */
export const postFileApi = (path, data) =>
  apiClient.post(path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

/**
 * PUT request with data
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @returns {Promise} - Axios promise
 */
export const putApi = (path, data) => apiClient.put(path, data);

/**
 * PATCH request with data
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @returns {Promise} - Axios promise
 */
export const patchApi = (path, data) => apiClient.patch(path, data);

/**
 * DELETE request with optional data
 * @param {string} path - API endpoint
 * @param {object} data - Optional data to send
 * @returns {Promise} - Axios promise
 */
export const deleteApi = (path, data) => apiClient.delete(path, { data });

/**
 * POST request with custom token
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @param {string} token - Custom token
 * @returns {Promise} - Axios promise
 */
export const postWithCustomTokenApi = (path, data, token) =>
  axios.post(path, data, {
    headers: {
      Authorization: token ? `Basic ${token}` : null,
      "Content-Type": "application/json",
    },
  });

// The  old format  now  changed    to  new one in above

// import axios from "axios";
// import { BASEURL } from "services";

// axios.defaults.baseURL = BASEURL;

// /**
//  *
//  * @param {*} path  endpoint
//  * @returns data from api
//  */
// export const getApi = (path) => {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: authToken ? Bearer ${authToken} : null,
//     },
//   };
//   return axios.get(path, config);
// };

// export const getAPIWithoutToken = (path) => {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: authToken ? Bearer ${authToken} : null,
//     },
//   };
//   return axios.get(path, config);
// };

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export const postApi = (path, data) => {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: authToken ? Bearer ${authToken} : null,
//     },
//   };
//   return axios.post(path, data, config);
// };

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export const postFileApi = (path, data) => {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: authToken ? Bearer ${authToken} : null,
//       "Content-Type": "multipart/form-data",
//     },
//   };
//   return axios.post(path, data, config);
// };

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export function putApi(path, data) {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: Bearer ${authToken},
//     },
//   };

//   return axios.put(path, data, config);
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export function patchApi(path, data) {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: Bearer ${authToken},
//     },
//   };

//   return axios.patch(path, data, config);
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data params
//  * @returns   data from api
//  */
// export function deleteApi(path, data) {
//   const authToken = JSON.parse(sessionStorage.getItem("auth"))?.access;
//   const config = {
//     headers: {
//       Authorization: Bearer ${authToken},
//     },
//   };

//   return axios.delete(path, { ...config });
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export function postWithCustomTokenApi(path, data, token) {
//   const config = {
//     headers: {
//       Authorization: token ? Basic ${token} : null,
//       "Content-Type": "application/json",
//     },
//   };

//   return axios.post(path, data, config);
// }
